import revive_payload_client_tegjKc3sWh from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5007jGkrry from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0QxjWARCSt from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_JnCqJyQ1rH from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ke6FwZ1mk4 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gQIlhMjRhp from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_E6t4fpMbDj from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_6UD6qGEJ0G from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/midea/miraco-website/.nuxt/components.plugin.mjs";
import prefetch_client_gtbSPp33Qz from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_y2jhpz4h56k7qfnppzi2lwkige/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_B5rUrJv4Gu from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@playwright+test@1.45.3_@types+node@22.0.0_encodin_ui2iahzkdhfx22o6wcwqluagxy/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import sentry_client_LGol3mrYAI from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@robustastudio+sentry@1.0.0-beta.17_encoding@0.1.13_magicast@0.3.4_rollup@3.29.4_vue@3.4.34_typescript@5.5.4_/node_modules/@robustastudio/sentry/dist/runtime/plugins/sentry.client.mjs";
import pwa_client_flWwTHtgO7 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@vite-pwa+nuxt@0.5.0_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@3.29.4_vite@5.4.5_c5peb5l6sb6zqsavq2ruwb3gea/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_94qiCgupX3 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@3.29.4/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vJMX8vIrJ6 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.5.4/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_62FsFJyMLV from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@robustastudio+e-commerce@1.45.0-develop.33_encoding@0.1.13_magicast@0.3.4_rollup@3.29.4_vite_c74lt3zgrqniabssmervzswm2e/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_GuAYU8Ghs9 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@robustastudio+e-commerce@1.45.0-develop.33_encoding@0.1.13_magicast@0.3.4_rollup@3.29.4_vite_c74lt3zgrqniabssmervzswm2e/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_RQvvPgZnA8 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.4_rollup@3.29.4_vue@3.4.34_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import essentials_client_lWUJ2t2Kpq from "/builds/robustastudio/midea/miraco-website/plugins/essentials.client.ts";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/midea/miraco-website/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/midea/miraco-website/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/midea/miraco-website/plugins/setup.ts";
export default [
  revive_payload_client_tegjKc3sWh,
  unhead_5007jGkrry,
  router_0QxjWARCSt,
  payload_client_JnCqJyQ1rH,
  navigation_repaint_client_Ke6FwZ1mk4,
  check_outdated_build_client_gQIlhMjRhp,
  view_transitions_client_E6t4fpMbDj,
  chunk_reload_client_6UD6qGEJ0G,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gtbSPp33Qz,
  plugin_B5rUrJv4Gu,
  sentry_client_LGol3mrYAI,
  pwa_client_flWwTHtgO7,
  nuxt_plugin_94qiCgupX3,
  plugin_vJMX8vIrJ6,
  gtm_client_62FsFJyMLV,
  ssr_caching_GuAYU8Ghs9,
  i18n_RQvvPgZnA8,
  essentials_client_lWUJ2t2Kpq,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]