export const appKeepalive = false

export const appLayoutTransition = true

export const appViewTransition = true

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"hid":"infopid-livechat","innerHTML":"\n          (function (I, n, f, o, b, i, p) {\n            I[b] =\n              I[b] ||\n              function () {\n                (I[b].q = I[b].q || []).push(arguments);\n              };\n            I[b].t = 1 * new Date();\n            i = n.createElement(f);\n            i.async = 1;\n            i.src = o;\n            p = n.getElementsByTagName(f)[0];\n            p.parentNode.insertBefore(i, p);\n          })(window, document, 'script', 'https://livechat.infobip.com/widget.js', 'liveChat');\n        ","type":"text/javascript"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appPageTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = true

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000